body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Nunito", Sans-serif;
  background-color: #F7F7F7;
}
a {
  text-decoration: none;
  color: inherit;
}
#root {
  min-height: 100vh;
}
.semi-bold-font {
  font-weight: 600;
}
.bold-font {
  font-weight: 700;
}
.light-font {
  font-weight: 300;
}
.heading {
  font-size: 1.7rem;
}
.sub-heading {
  font-size: 1.2rem;
}
.helper-text {
  font-size: 0.8rem;
}
.spinner {
  width: 100vw;
  height: 100vh;
}

.flex-container {
  display: flex !important;
}
.direction-column {
  flex-direction: column;
}
.align-center {
  justify-content: center;
  align-items: center;
}
.align-end {
  justify-content: flex-end;
  align-items: flex-end;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.top-margin {
  margin-top: 1rem !important;
}
.top-margin-half {
  margin-top: 0.5rem !important;
}
.margin-horizontal {
  margin-left: 1rem;
  margin-right: 1rem;
}
.padding-one {
  padding: 1rem;
}
.text-align-center {
  text-align: center;
}
.hide {
  display: none;
}
.barGraphTooltip, .heatMapTooltip, .stackGraphTooltip {
  position: fixed;
  background-color: white;
  border-radius: 5px;
  opacity: 0.8;
  font-size: 13px;
  border: 1px dashed #3366ff;
  padding: 10px;
}
.graph-label {
  margin: 10px;
}
.no-data-icon {
  width: 20%;
  max-width: 70px;
  opacity: 0.7;
}